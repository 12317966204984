import { Component, OnInit } from '@angular/core';
import { GalleryItem, ImageItem } from '@ngx-gallery/core';

@Component({
  selector: 'app-instalaciones',
  templateUrl: './instalaciones.component.html',
  styleUrls: ['./instalaciones.component.scss']
})
export class InstalacionesComponent implements OnInit {

  images: GalleryItem[];

  constructor() { }

  ngOnInit(): void {
    this.images = [
      new ImageItem({
        thumb: 'assets/galeria/small/1.jpeg',
        src: 'assets/galeria/1.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/2.jpeg',
        src: 'assets/galeria/2.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/3.jpeg',
        src: 'assets/galeria/3.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/4.jpeg',
        src: 'assets/galeria/4.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/5.jpeg',
        src: 'assets/galeria/5.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/6.jpeg',
        src: 'assets/galeria/6.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/7.jpeg',
        src: 'assets/galeria/7.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/8.jpeg',
        src: 'assets/galeria/8.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/9.jpeg',
        src: 'assets/galeria/9.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/10.jpeg',
        src: 'assets/galeria/10.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/11.jpeg',
        src: 'assets/galeria/11.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/12.jpeg',
        src: 'assets/galeria/12.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/14.jpeg',
        src: 'assets/galeria/14.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/15.jpeg',
        src: 'assets/galeria/15.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/16.jpeg',
        src: 'assets/galeria/16.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/17.jpeg',
        src: 'assets/galeria/17.jpeg',
      })
    ];
  }
}
