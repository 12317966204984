<div class="con4">
    <div class="container">
        <h3 class="card-title">PERSONAL</h3>
        <p class="card-text ">Contamos personal especializado con un alto nivel de experiencia en pacientes con
            Alzheimer, demencia senil y demás
            condiciones que necesiten cuidado especializad, atendemos las necesidades y discapacidades de nuestros
            residentes con
            el mayor profesionalismo y amor.
        </p>


        <div class="card mb-3">
            <div class="row no-gutters">
                <div class="col-md-4">
                    <img class="card-img-top" src="assets/images/staff/kim.jpeg" alt="personal1">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h5 class="card-title">Michelle Lopez Acosta</h5>
                        <p class="card-text">Trabajadora Social Egresada de la Corporación Universitaria Minuto de
                            Dios,
                            Diplomado en
                            Gestión del Desarrollo Municipal y Regional con 3 años de experiencia en Gerontología
                            Social,
                            atención a
                            población en condición de vulnerabilidad, adulto mayor, niños, niñas adolescentes y
                            familias.
                        </p>
                        <p class="card-text"><small class="text-muted">Directora</small></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-3">
            <div class="row no-gutters">
                <div class="col-md-4">
                    <img class="card-img-top" src="assets/images/staff/doc.jpeg" alt="Card image cap">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h5 class="card-title">Brandon Arevalo Bernal</h5>
                        <p class="card-text">Médico general caracterizado por un alto nivel de liderazgo; con capacidad
                            en
                            el manejo integral de
                            las patologías, basado en múltiples herramientas y conocimientos, además de un adecuado
                            proceso
                            de enfoque, análisis, diagnóstico y manejo de ellas; con el fin de lograr una adecuada
                            relación
                            médico-paciente basado en la claridad e influencia en pro de su bienestar.</p>
                        <p class="card-text"><small class="text-muted">Médico</small></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>