<div class="con3">
    <div class="container">
        <h3 class="card-title">Nuestras sedes</h3>
        <p class="card-text ">Contamos con numerosas sedes ubicadas en </p>

        <div class="row">

            <div class="col-12 col-lg-3 col-md-6 col-sm-6 ">
                <div class="card">

                    <div class="card-body">
                        <h5 class="card-title title-color">Sede Las Villas</h5>
                        <p class="card-text dir-text "> Carrera 56 # 128 - 35</p>
                        <p class="card-text dir-text "> 319 704 67 87</p>
                        <p class="card-text dir-text "> 314 461 21 33</p>
                        <p class="card-text dir-text "> 5 75 26 76</p>
                        <button class="btn btn-primary button-location" (click)="openMapsA()">Mapa</button>
                    </div>
                    <div class="card-footer">
                        <small class="text-muted">Barrio las villas</small>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-3 col-md-6 col-sm-6 ">
                <div class="card">

                    <div class="card-body">
                        <h5 class="card-title title-color">Normandía sede 1</h5>
                        <p class="card-text dir-text">Carrera 72 a # 51 - 73</p>
                        <p class="card-text dir-text">2 95 80 95</p>
                        <button class="btn btn-primary button-location" (click)="openMapsB()">Mapa</button>
                    </div>
                    <div class="card-footer">
                        <small class="text-muted">Barrio Normandía</small>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-3 col-md-6 col-sm-6 ">
                <div class="card">

                    <div class="card-body">
                        <h5 class="card-title title-color">Normandía sede 2</h5>
                        <p class="card-text dir-text">Carrera 73 # 51 - 30</p>
                        <p class="card-text dir-text">9 24 41 68</p>
                        <button class="btn btn-primary button-location" (click)="openMapsC()">Mapa</button>
                    </div>
                    <div class="card-footer">
                        <small class="text-muted">Barrio Normandía</small>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-3 col-md-6 col-sm-6 ">
                <div class="card">

                    <div class="card-body">
                        <h5 class="card-title title-color">Normandía sede 3</h5>
                        <p class="card-text dir-text">Carrera 73 # 54 - 50</p>
                        <p class="card-text dir-text">7 74 60 03</p>
                        <button class="btn btn-primary button-location" (click)="openMapsD()">Mapa</button>
                    </div>
                    <div class="card-footer">
                        <small class="text-muted">Barrio Normandía</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>