import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactanos',
  templateUrl: './contactanos.component.html',
  styleUrls: ['./contactanos.component.scss']
})
export class ContactanosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }



  openWhastApp() {
    window.location.href = 'https://api.whatsapp.com/send?phone=573197046787';
  }

  openMaps() {
    window.location.href = 'https://www.google.com.sa/maps/search/4.714015%2C%20-74.065201?hl=es';
  }

  callNow() {
    window.location.href = 'tel:035752676';
  }


}
