<div class="con5">
    <div class="container">
        <h3 class="card-title">SERVICIOS</h3>
        <p class="card-text ">Sentimos el amor que ellos te brindaron y sabemos que quieres su bienestar. Contamos con
            más
            de 12 años de experiencia y conocimientos en el cuidado del adulto mayor.
        </p>


        <div class="row">
            <div class="col-6 col-sm-6 col-md-3">
                <div class="item-desc">
                    <img src="assets/icons/servicesico/24-7.svg">
                    <p>Cuidados de enfermería las 24 horas</p>
                </div>
            </div>
            <div class="col-6 col-sm-6 col-md-3">
                <div class="item-desc">
                    <img src="assets/icons/servicesico/food2.svg">
                    <p>5 Comidas diarias</p>
                </div>
            </div>
            <div class="col-6 col-sm-6 col-md-3">
                <div class="item-desc">
                    <img src="assets/icons/servicesico/doctor.svg">
                    <p>Valoración médico mensual</p>
                </div>
            </div>
            <div class="col-6 col-sm-6 col-md-3">
                <div class="item-desc">
                    <img src="assets/icons/servicesico/calendar.svg">
                    <p>Valoración nutricional trimestral</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-6 col-sm-6 col-md-3">
                <div class="item-desc">
                    <img src="assets/icons/servicesico/emotion.svg">
                    <p>Atención psicosocial</p>
                </div>
            </div>
            <div class="col-6 col-sm-6 col-md-3">
                <div class="item-desc">
                    <img src="assets/icons/servicesico/bed.svg">
                    <p>Habitaciones espléndidas confort y amplias</p>
                </div>
            </div>
            <div class="col-6 col-sm-6 col-md-3">
                <div class="item-desc">
                    <img src="assets/icons/servicesico/nature.svg">
                    <p>Magnificas zonas verdes</p>
                </div>
            </div>
            <div class="col-6 col-sm-6 col-md-3">
                <div class="item-desc">
                    <img src="assets/icons/servicesico/fun.svg">
                    <p>Terapia ocupacional</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-6 col-sm-6 col-md-3">
                <div class="item-desc">
                    <img src="assets/icons/servicesico/paint.svg">
                    <p>Actividades culturales y recreativas ( musicoterapia, gimnasia pasiva, pilates y
                        talleres)</p>
                </div>
            </div>
            <div class="col-6 col-sm-6 col-md-3">
                <div class="item-desc">
                    <img src="assets/icons/servicesico/pray.svg">
                    <p>Orientación religiosa</p>
                </div>
            </div>
            <div class="col-6 col-sm-6 col-md-3">
                <div class="item-desc">
                    <img src="assets/icons/servicesico/washing-machine.svg">
                    <p>Servicio de lavandería</p>
                </div>
            </div>
            <div class="col-6 col-sm-6 col-md-3">
                <div class="item-desc">
                    <img src="assets/icons/servicesico/watching-tv.svg">
                    <p>Televisión por cable y WIFI</p>
                </div>
            </div>
        </div>
    </div>
</div>