<div class="con7">
    <div class="container">
        <h3 class="card-title">PROGRAMAS</h3>
        <h2 class="card-sub-title">Hogar Geriátrico día (Guardería)</h2>
        <p class="card-text ">El poder compartir con personas de su misma edad tener actividades diferentes cada día y
            mantener la mente ocupada le
            ayudará al adulto mayor con su bienestar físico y emocional Por esto ofrecemos el hogar de día con los
            siguientes servicios</p>
        <ul>
            <li>5 comidas diarias, 3 principales 2 refrigerios</li>
            <li>Terapia física y ocupacional</li>
        </ul>
        <h2 class="card-sub-title">Hogar Geriátrico permanente</h2>

        <p class="card-text ">
            Es un honor para nosotros poder brindar a nuestros adultos mayores un hogar donde reciban el mejor cuidado
            profesional
            en excelentes instalaciones adecuadas especialmente para sus necesidades y así se sientan como en casa.
        </p>
    </div>
</div>