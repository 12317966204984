<div class="con3">
    <div class="container">
        <h3 class="card-title">INSTALACIONES</h3>
        <p class="card-text ">En el hogar El arte de mi vejez hemos perfeccionado cada uno de nuestros espacios,
            contamos
            con
            los mejores y más confortables espacios en el sector Gerontológico. </p>
        <ul>
            <li> Hermosas habitaciones de lujo amplias</li>
            <li> Sala de televisión amplia y confortable.</li>
        </ul>
        <div class="row justify-content-md-center">
            <gallery [items]="images" [gestures]="true" [autoPlay]="true"></gallery>
        </div>
    </div>
</div>