import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sedes',
  templateUrl: './sedes.component.html',
  styleUrls: ['./sedes.component.scss']
})
export class SedesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openMapsA() {
    window.location.href = 'https://www.google.com.sa/maps/search/4.714015%2C%20-74.065201?hl=es';
  }

  openMapsB() {
    window.location.href = 'https://www.google.com.sa/maps/search/4.670517740379377%2C%20-74.10878801117981?hl=es';

  }

  openMapsC() {
    window.location.href = 'https://www.google.com.sa/maps/search/4.671073%2C%20-74.109578?hl=es';

  }

  openMapsD() {
    window.location.href = 'https://www.google.com.sa/maps/search/4.673713%2C%20-74.106752?hl=es';
  }

}
