<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand " href="#">
        <img src="assets/images/logoA.png" height="70" width="155" class="d-inline-block " alt="">
    </a>

    <!-- <p class="title-logo">Hogar gerontológico <span> San José de amor </span></p> -->

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse nav-move" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto ">
            <li class="nav-item active">
                <a class="nav-link" href="#" (click)="goToS1()" data-toggle="collapse"
                    data-target="#navbarSupportedContent">Home
                    <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#" (click)="goToS2()" data-toggle="collapse"
                    data-target="#navbarSupportedContent">Nosotros</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#" (click)="goToS3()" data-toggle="collapse"
                    data-target="#navbarSupportedContent">Instalaciones</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#" (click)="goToS4()" data-toggle="collapse"
                    data-target="#navbarSupportedContent">Servicios</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#" (click)="goToS6()" data-toggle="collapse"
                    data-target="#navbarSupportedContent">Programa</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#" (click)="goToS7()" data-toggle="collapse"
                    data-target="#navbarSupportedContent">Contáctenos</a>
            </li>
            <!-- <li class="nav-item">
        <a class="nav-link disabled" href="#">Disabled</a>
      </li> -->
        </ul>
    </div>
</nav>