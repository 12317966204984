<script>
    $('.carousel').carousel({
        interval: 20000,
        ride: true

    });
</script>
<div class="con1">
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner carousel-inner-h">
            <div class="carousel-item active slideg slide1">
                <div></div>
                <div class="container">
                    <div class="row justify-content-md-center cont-size ">
                        <div class="col-12 align-self-center">
                            <div class="data">
                                <div class="data_down">
                                </div>
                            </div>

                        </div>
                        <div class="col-12 align-self-center">
                            <div class="data">
                                <div class="data_down">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item slideg slide2">
                <div class="container">
                    <div class="row justify-content-md-center cont-size slide2img">
                        <div class="col-12 align-self-end no-padding">
                            <div class="info-contact">
                                <div class="pattern">
                                    <h1>
                                        Contáctanos y separa tu cupo
                                    </h1>
                                    <table>
                                        <tr (click)="openMaps()">
                                            <td class="wsicon bounce"><img src="assets/icons/location.svg"></td>
                                            <td class="info">Cra 56 # 128 - 35 Las villas</td>
                                        </tr>
                                        <tr (click)="openWhastApp()">
                                            <td class="wsicon bounce"><img src="assets/icons/whatsapp.svg"></td>
                                            <td class="info">319 7 04 67 87</td>
                                        </tr>
                                        <tr (click)="callNow()">
                                            <td class="wsicon bounce"><img src="assets/icons/call.svg"></td>
                                            <td class="info table-downoffset">5 75 26 76</td>
                                        </tr>
                                        <tr>
                                            <td class="wsicon " style="height: 50px; width: 20px;"></td>
                                            <td class="info table-downoffset"></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</div>