<div class="con6">
    <div class="container">
        <div class="title-icon">
            Siguenos en
        </div>
        <div class="banner-div">
            <div class="row justify-content-md-center cont-size ">
                <div class="col-12 align-self-center">
                    <a href="https://www.facebook.com/Hogar-Gerontol%C3%B3gico-El-Arte-De-Mi-Vejez-113704483820975">
                        <img class="img-ico" src="assets/icons/facebook.svg" alt="">
                    </a>
                    <a href="https://www.instagram.com/hogar_el_arte_de_envejecer/">
                        <img class="img-ico" src="assets/icons/insta.svg" alt="">
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=573197046787">
                        <img class="img-ico" src="assets/icons/whatsapp.svg" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="contact">
        <div class="subcontainer">
            <div class="container">
                <h2 class="titleBack">El arte de mi vejez</h2>
                <h3 class="title">Reserva ya !</h3>
                <table>
                    <tr (click)="callNow()">
                        <td>
                            <img class="bounce" src="assets/icons/call.svg" />
                        </td>
                        <td class="sub_line">5 75 26 76</td>
                    </tr>
                    <tr (click)="openWhastApp()">
                        <td>
                            <img class="bounce" src="assets/icons/whatsapp.svg" />
                        </td>
                        <td class="sub_line">319 7 04 67 87</td>
                    </tr>
                    <tr (click)="openMaps()">
                        <td>
                            <img class="bounce" src="assets/icons/location.svg" />
                        </td>
                        <td class="sub_line">Cra 56 # 128 - 35 Las villas</td>
                    </tr>
                </table>
            </div>
        </div>


        <div class="mapouter">
            <div class="gmap_canvas">
                <iframe class="iframec" id="gmap_canvas"
                    src="https://maps.google.com/maps?q=4.714015%2C%20-74.065201&t=&z=17&ie=UTF8&iwloc=&output=embed"
                    frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </div>
            <style>
                .mapouter {
                    text-align: right;
                    height: 500px;
                    width: 100%;
                }

                .gmap_canvas {
                    overflow: hidden;
                    background: none !important;
                    height: 100%;
                    width: 100%;
                }
            </style>
        </div>
    </div>
</div>