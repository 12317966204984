<div class="con2">
    <div class="container">
        <div class="row justify-content-md-center">
            <div class="card mb-3">
                <img class="card-img-top" src="assets/images/fachada.jpeg" alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">QUIENES SOMOS</h5>
                    <p class="card-text "> En el arte de mi vejez entendemos la importancia de cuidar de aquellos a
                        quienes amamos por eso es un honor para
                        nosotros poder brindar a nuestra familia de adultos mayores el respeto y los cuidados que ellos
                        requieren ofreciéndoles
                        un hogar cómodo, amoroso y tranquilo.
                    </p>
                    <p class="card-text"><small class="text-muted">Los abuelos son una perfecta mezcla de risas,
                            historias llenas de amor y sabiduria</small></p>
                </div>
            </div>
        </div>

    </div>
</div>